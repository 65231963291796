import React, { Component } from "react";
import Fade from "react-reveal";

class Footer extends Component {
  render() {
    if (!this.props.data) return null;

    return (
      <footer>
        <div className="row">
          <Fade bottom>
            <div className="twelve columns">

              <ul className="social-links">
                <div className="footer-text">strony poprzednich zlotów</div>
                <a className="footer" href="http://www.zlotpoziomek.pl/2023/">
                2023
              </a>
              &nbsp;  &nbsp;
                <a className="footer" href="http://www.zlotpoziomek.pl/2022/">
                2022
              </a>
              &nbsp;  &nbsp;
              <a className="footer" href="http://www.zlotpoziomek.pl/2021/">
                2021
              </a>
              &nbsp;  &nbsp;
              <a className="footer" href="http://www.zlotpoziomek.pl/2020/">
                2020
              </a>
              &nbsp;  &nbsp;
              <a className="footer" href="http://www.zlotpoziomek.pl/2019/">
                2019
              </a>
              &nbsp;  &nbsp;
              <a className="footer" href="http://www.zlotpoziomek.pl/2018/">
                2018
              </a>
              &nbsp;  &nbsp;
              <a className="footer" href="http://www.zlotpoziomek.pl/2017/">
                2017
              </a>
               
      
      </ul>

             
            </div>
          </Fade>

          <div id="go-top">
            {/* <a className="smoothscroll" title="Back to Top" href="#home">
              <i className="icon-up-open"></i>
            </a> */}
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
