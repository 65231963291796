import React, { Component } from "react";
import ReactGA from "react-ga";
import $ from "jquery";
import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Accomodation from "./Components/Accomodation";
import Registration from "./Components/Registration";
import Contact from "./Components/Contact";
import Schedule from "./Components/Schedule"
import HowToGet from "./Components/HowToGet";
import TShirts from "./Components/TShirts";
import Routes from "./Components/Routes";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foo: "bar",
      resumeData: {}
    };

    ReactGA.initialize("UA-110570651-1");
    ReactGA.pageview(window.location.pathname);
  }

  getResumeData() {
    $.ajax({
      url: "./resumeData.json",
      dataType: "json",
      cache: false,
      success: function(data) {
        this.setState({ resumeData: data });
      }.bind(this),
      error: function(xhr, status, err) {
        console.log(err);
        alert(err);
      }
    });
  }

  componentDidMount() {
    this.getResumeData();
  }

  render() {
    return (
      <div className="App">
        <Header data={this.state.resumeData.main} />
        {/* <Accomodation data={this.state.resumeData.main} />
        <HowToGet data={this.state.resumeData.howtoget} />
        <Registration data={this.state.resumeData.main} />
        <Schedule data={this.state.resumeData.main} />
        <Routes data={this.state.resumeData.routes} />
        <TShirts data={this.state.resumeData.main}/>
        <Contact data={this.state.resumeData.main} /> */}
        <Footer data={this.state.resumeData.main} />
      </div>
    );
  }
}

export default App;
